export const ENROL_PATH = '/enrol';
export const FORGOT_PASSWORD_PATH = '/forgot-password';
export const LOGIN_PATH = '/login';
export const SIGNUP_PATH = '/sign-up';
export const TERMS_PATH = 'https://www.inspringcareers.com/terms-and-conditions';
export const PRIVACY_PATH = 'https://www.inspringcareers.com/privacy-policy';

export const CANDIDATE_INVOICES_PATH = '/candidate/invoices';
export const CANDIDATE_DASHBOARD_PATH = '/candidate/dashboard';
export const CANDIDATE_JOBS_PATH = '/candidate/jobs';
export const CANDIDATE_COURSES_PATH = '/candidate/courses';
export const CANDIDATE_MY_PROFILE_PATH = '/candidate/my-profile';
export const CANDIDATE_ADVISOR_PATH = '/candidate/advisor';
export const CANDIDATE_RESUME_PATH = '/candidate/resume';
export const CANDIDATE_DOCUMENTS_PATH = '/candidate/documents';
export const CANDIDATE_SETTINGS_PATH = '/candidate/settings';

export const ADVISOR_CANDIDATES_PATH = '/advisor/candidates';
export const ADVISOR_INVITATIONS_PATH = '/advisor/invitations';
export const ADVISOR_MY_PROFILE_PATH = '/advisor/my-profile';
export const ADVISOR_SETTINGS_PATH = '/advisor/settings';

export const ADMIN_DASHBOARD_PATH = '/admin/dashboard';
export const ADMIN_EMPLOYEES_PATH = '/admin/employees';
export const ADMIN_INVITATIONS_PATH = '/admin/invitations';
export const ADMIN_CANDIDATES_PATH = '/admin/candidates';
export const ADMIN_SETTINGS_PATH = '/admin/settings';
export const ADMIN_CONFIG_BASE_PATH = '/admin/config';
export const ADMIN_TEMPLATES_PATH = `${ADMIN_CONFIG_BASE_PATH}/templates`;
export const ADMIN_COURSES_PATH = `${ADMIN_CONFIG_BASE_PATH}/courses`;
export const ADMIN_PROGRAMS_PATH = `${ADMIN_CONFIG_BASE_PATH}/programs`;
export const ADMIN_COHORTS_PATH = `${ADMIN_CONFIG_BASE_PATH}/cohorts`;

export const SOCIAL_LOGIN_FACEBOOK_PATH = '/api/sso/facebook';
export const SOCIAL_LOGIN_GOOGLE_PATH = '/api/sso/google';
export const SOCIAL_LOGIN_LINKED_PATH = '/api/sso/linkedin';
